var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('banner',{attrs:{"bold-title":"Reserva","title":(" de charlas técnicas - " + (_vm.user.fairName)),"type":"banner--admin"}}),_c('div',{staticClass:"pt-3 px-7"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-end"},[_c('v-btn',{staticClass:"text--primary text-none elevation-0",attrs:{"color":"secondary","dark":""},on:{"click":_vm.onCreate}},[_vm._v(" Crear charla ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('data-table',{ref:"conferenceBookings",attrs:{"params":_vm.params,"url":_vm.url,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.bookingConferenceStatus[item.status].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.bookingConferenceStatus[item.status].description)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"primary","disabled":item.status === 'rejected'},on:{"click":function($event){return _vm.onEdit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar charla")])])]}}])})],1)]),(_vm.step<12)?_c('pending-booking'):_vm._e()],1),_c('conference-booking-modal',{ref:"conferenceBookingModal"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h4',[_vm._v(" Importante: "),_c('span',{staticStyle:{"font-weight":"initial !important"}},[_vm._v(" Si tienes una charla aprobada y necesitas cancelarla, por favor haz la solicitud a los correos logistica@fise.co y fise@fise.co ")])])])])}]

export { render, staticRenderFns }